import React from "react"
import tw, { styled } from "twin.macro"
import "styled-components/macro"
import Layout from "../components/Layout"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import _ from "lodash"
import Header from "../components/Header"

const Container = styled.div`
  ${tw`p-5`}
`
const SponsorsContainer = styled.div`
  ${tw`
    grid gap-8 grid-cols-2 md:grid-cols-3 xl:grid-cols-4 mt-5
  `}
`
const ImageLinkContainer = styled.div`
  ${tw`flex items-center justify-center`}
`
const Anchor = styled.a`
  ${({ disabled }) => disabled && tw`pointer-events-none`}
`

const SponsorPage = ({
  data: {
    markdownRemark: {
      frontmatter: { seo, title, sponsors },
    },
  },
  location,
}) => {
  return (
    <Layout
      seoTitle={seo?.title || title}
      seoDescription={seo?.description}
      location={location}
    >
      <Container>
        {title && <Header title={title} />}
        <SponsorsContainer>
          {sponsors.map(({ name, image, url }, idx) => (
            <ImageLinkContainer key={idx}>
              <Anchor
                key={idx}
                href={url ? url : "#"}
                target="_blank"
                title={name}
                disabled={_.isEmpty(url)}
              >
                <GatsbyImage
                  image={getImage(image)}
                  alt={name || "Sponsor logo"}
                />
              </Anchor>
            </ImageLinkContainer>
          ))}
        </SponsorsContainer>
      </Container>
    </Layout>
  )
}

export default SponsorPage

export const pageQuery = graphql`
  query SponsorPageQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        seo {
          title
          description
        }
        title
        sponsors {
          url
          name
          image {
            childImageSharp {
              gatsbyImageData(height: 250)
            }
          }
        }
      }
    }
  }
`
